// 路由器
import VueRouter from 'vue-router'
import Cookie from 'js-cookie'
const router = new VueRouter({
  // mode: 'history',
  base:'/dist/',
  routes: [
    {
      path: '/',
      component: () => import('../views/index/index'),
      name: 'index',
      meta: { title: '登录' }
    },
    {
      path: '/index',
      name: 'index',
      component: () => import('../views/index/index'),
      meta: { title: '登录' }
    },
    {
      path: '/exam',
      name: 'exam',
      component: () => import('../views/exam/index'),
      meta: { title: '登录' }
    },
    {
      path: '/answer',
      name: 'answer',
      component: () => import('../views/exam/answer'),
    },
    {
      path: '/paper',
      name: 'paper',
      component: () => import('../views/exam/paper'),
    },
    {
      path: '/test',
      name: 'test',
      component: () => import('../views/test/index'),
    },
    {
      path: '/train',
      name: 'train',
      component: () => import('../views/train/index'),
    },
    {
      path: '/wrong',
      name: 'wrong',
      component: () => import('../views/wrong/index'),
    },
    {
      path: '/wrongDetail',
      name: 'wrongDetail',
      component: () => import('../views/wrong/detail'),
    },
    {
      path: '/collect',
      name: 'collect',
      component: () => import('../views/collect/index'),
    },
    {
      path: '/collectDetail',
      name: 'collectDetail',
      component: () => import('../views/collect/detail'),
    },
    {
      path: '/statistics',
      name: 'statistics',
      component: () => import('../views/statistics/index'),
    },
    {
      path: '/center',
      name: 'center',
      component: () => import('../views/center/index'),
    },
    {
      path: '/course',
      name: 'course',
      component: () => import('../views/course/index'),
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('../views/login/login'),
      meta: { title: '登录' }
    },
    {
      path: '/*',
      name: '404',
      component: () => import('../views/404'),
    },
  ],
})

// to到哪去， from从哪来，next执行下一步
router.beforeEach((to, from, next) => {
  const token = Cookie.get('token')
  if (to.name === 'answer' || to.name === 'course') {
    next();
  } else if (!token && to.name !== 'login') { // 未登录时，以及未登录访问其他页面时，跳转到login页面
    next({ name: 'login' })
  } else if (token && to.name === 'login') { // 当已经登录，还打开了登录页面，直接跳转到对应的内容页面首页
    next({ name: 'home' })
  } else { // 在这里，过滤下，看是否有权限能访问
    next()
  }
  next()
})

// 获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)  //修改原型对象中的push方法,解决重复刷新报错的问题
}

export default router
