import Vue from 'vue'
import Vuex from 'vuex'
import tab from './tab'
import userinfo from './userinfo'
Vue.use(Vuex)

const store =  new Vuex.Store({
    modules: {
        tab, userinfo
    }
})
export default store
// 导出store
