export default {
  state: {
    token: '',
    userinfo: '',
  },
  actions: {},
  mutations: {
    setToken(state, val){ // 设置左侧导航激活ID 
      state.token = val
      localStorage.setItem('token', val);
    },
    setUserInfo(state, val){ // 设置左侧导航激活ID 
      state.userinfo = JSON.stringify(val)
      localStorage.setItem('userinfo', JSON.stringify(val));
    },
  },
}
