import Cookie from 'js-cookie'
export default {
  state: {
    isCollapse: false,
    menu: [],
    tabList: [
    ],
    menuActive: '', // 左侧导航跟激活
    menuActiveId: '',
    tagList: '', // tag列表
    tagActive: '', // tag激活ID
    roleArr: [],
    activeIndex: '', // 刷新时，默认激活的页面
    submenuList: [],
    navList: [],//二级导航列表,
    sonTitle:''
  },
  actions: {},
  mutations: {
    setMenuActive(state, val){ // 设置左侧导航激活ID 
      localStorage.setItem('menuActive', val)
      state.menuActive = val
    },
    getSonTitle (state, val) { 
      localStorage.setItem('sonTitle', val);
      state.sonTitle = val
    },

    SetTagActive(state, val) { // 设置激活的tabID
      const munuArr = JSON.parse(localStorage.getItem('menu'))
      munuArr.forEach(item=>{
        item.children.forEach(sonItem => {
          if(val == sonItem.id){
            state.menuActiveId = item.id
            localStorage.setItem('menuActiveId', item.id)
          }
        })
      })
      state.tagActive = val
    },

    SetMentList (state, val) {
      localStorage.removeItem('submenuList')
      localStorage.setItem('submenuList', JSON.stringify(val));
      state.submenuList = val
    },
    getActiveIndex(state, val) { // 刷新的时候也面显示的tag使用
      state.activeIndex = val
    },

    selectMenu(state, val) {    // 选择对应菜单，将对应的name塞到taBlist里面去
      if (val.name !== 'home') {
        const index = state.tabList.findIndex((item) => item.id === val.id)
        if (index == -1) {
          state.tabList.push(val)
        }
      }
      console.log(state.tabList,'newTabList');
    },
    reMoveTaglist(state) {    // 选择对应菜单，将对应的name塞到taBlist里面去
      state.tabList = []
    },

    plusMenu(state, val) {
      if (val.name !== 'home') {
        const index = state.tabList.findIndex((item) => item.name === val.name)
        if (index != -1) {
          delete state.tabList[index]
        }
        Cookie.set('taglist', JSON.stringify(state.tabList))
      }
    },
    setMenu (state, val) { // 设置左侧菜单项
      const map  = val.map(item => {
        const sonChild = []
        if(item.children && item.children.length > 0){
          item.children.forEach(element => {
            sonChild.push({
              id:element.id,
              menuIcon:element.menuIcon,
              menuUrl:element.menuUrl,
              code: element.code,
              name: element.name,
              ejectStatus: element.ejectStatus
            })
          });
        }
        return {
          children: sonChild,
          id:item.id,
          menuIcon:item.menuIcon,
          menuUrl:item.menuUrl,
          code: item.code,
          name: item.name,
          ejectStatus: item.ejectStatus
        }
      })
      state.menu = map
      localStorage.setItem('menu', JSON.stringify(map));  
    }

  },
}
