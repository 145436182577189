import Vue from 'vue'
import App from './App.vue'
import store from './store'
import VueRout from "vue-router";
// 引入路由
import router from './router/index'
import api from './api/index.js'
import ElementUI from 'element-ui';                      // 引入element-ui
import 'element-ui/lib/theme-chalk/index.css';           // element-ui的css样式要单独引入
// import './api/mock'
import * as echarts from 'echarts'

import dataV from '@jiaminghi/data-view' //引入dataV前端组件
Vue.use(dataV)
Vue.prototype.$echarts = echarts;
Vue.prototype.$api = api
Vue.use(VueRout)
Vue.use(ElementUI)
Vue.config.productionTip = false

new Vue({
  store,
  render: h => h(App),
  router
}).$mount('#app')
