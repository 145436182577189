<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {},
  data () {
    return {
    }
  },
  created () {

  },
  methods: {

  }
}
</script>

<style lang="scss">
@import url("@/assets/css/public.css");
.wrap{
  width: 1350px;
  min-height: 800px;
  font-size: 14px;
  margin: 20px auto;
}
</style>